import * as React from "react";
import CallToAction from "../components/CallToAction/CallToAction";
import Layout from "../components/Layout/Layout";
import Companies from "../components/Companies/Companies";
import Masthead from "../components/Masthead/Masthead";
import Service from "../components/Service/Service";
import TextMedia from "../components/TextMedia/TextMedia";
import MastImage from "../images/industries/construction-header.jpg";
import Testimonial from "../components/Testimonial/Testimonial";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Title = () => {
  return (
    <div className='mb-5'>
      <h2 className='title italic'>
        A simple way of increasing efficiency by having a clear plan of your
        workload.
      </h2>
      <p>
        Identify spare capacity or improve resource planning by using a using a
        simple tool designed to help you manage your time more efficiently. T
        Card boards and cards can be designed specifically for the construction
        and related industries.
      </p>
    </div>
  );
};

const ctaData = [
  {
    text: "Improving workflow for any organisation is essential. Keeping the plan clear, precise, and simple often leads to improved results and increases in efficiency.",
  },
  {
    text: "Allocate tasks however you choose using Row Grouping to see who is allocated to what jobs or custom Column Names to see what stage jobs/tasks are at.",
  },
  {
    text: "Accessible, clear and precise information helps improve customer service.",
  },
  {
    text: "Adaptable to any application Boards and Card Designs can be adjusted any time to improve efficiency.",
  },
];

const quoteData = {
  text: "I really like the system - it works brilliantly for remote working. It is the most simple Visual Management Tool on the internet.",
  author: "Mark Rigby, Managing Director, MTR Logistics",
  image: <StaticImage src='../images/industries/mtr-test.jpg' alt='' />,
};

const screensData = [
  {
    title: "Calendar or Custom Boards",
    text: "Quickly create and edit boards using dates or custom column names. Create and name Row Groups to allocate tasks to installers or engineers.",
    image: (
      <StaticImage
        src='../images/industries/construction/board.jpg'
        alt='Calendar or Custom Boards'
      />
    ),
  },
  {
    title: "Unlimited Card Designs",
    text: "Card Designs can easily be created to suit any process using one of our design templates or simply create your own.",
    image: (
      <StaticImage
        src='../images/industries/construction/card.jpg'
        alt='Unlimited Card Designs'
      />
    ),
  },
  {
    title: "Flexible User Permissions",
    text: "Change permissions to restrict the access or editing rights for all team members. Choose who can edit or view anything from Boards to Row Groups to individual fields. Changes here are reflected on the APP.",
    image: (
      <StaticImage
        src='../images/industries/construction/permission.jpg'
        alt='Flexible User Permissions'
      />
    ),
  },
];

const ConstructionPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>
          T Cards Online | Agile Construction & Property Services Management
        </title>
      </Helmet>

      <Masthead image={MastImage}>
        <h1>Agile Construction & Property Services Management</h1>
      </Masthead>
      <CallToAction data={ctaData} sectionTitle={<Title />} />
      <Testimonial data={quoteData} />
      <TextMedia data={screensData} />

      <Service />
      <Companies title='Trusted across the industry' logos={data} />
    </Layout>
  );
};

export default ConstructionPage;

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/images/logos/construction/" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(height: 115)
          }
        }
      }
    }
  }
`;
